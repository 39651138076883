<template>
  <div>
    <vs-popup class="sm:popup-w-60" title="Edit Item RAB" :active="isActive" v-on:update:active="emitModalIsActive">

      <div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/3 w-full -mt-1.5">
            <label class="ml-1 text-xs opacity-75 -mt-1">COA *</label>
            <vx-input-group>
              <vs-input placeholder="Pilih COA" :value="data.coa.nama" readonly/>
              <template slot="append">
                <div class="append-text btn-addon">
                  <vs-button type="filled" icon-pack="feather" icon="icon-search" @click="isModalCoaActive = true"/>
                </div>
              </template>
            </vx-input-group>
          </div>
          <div class="vx-col sm:w-1/3 w-full -mt-1">
            <label class="ml-1 text-xs opacity-75">Group 1 *</label>
            <v-select :options="group1s"  @input="onChangeGroup1" label="nama" :reduce="item => item.id" :clearable="false" v-model="data.id_item_group1"/>
            <!--
            <label class="ml-1 text-xs">Group 1 *</label>
            <vs-select v-model="data.group1" class="w-full">
              <vs-select-item v-for="(item, index) in ['RAB Proyek', 'RAB Infrastruktur', 'RAB Unit']" :key="index" :value="item" :text="item"/>
            </vs-select>
            -->
          </div>
          <div class="vx-col sm:w-1/3 w-full -mt-1">
            <label class="ml-1 text-xs opacity-75">Group 2</label>
            <v-select :options="group2s" label="nama" :reduce="item => item.id" :clearable="false" v-model="data.id_item_group2"/>
            <!--
            <vs-input class="w-full" label-placeholder="Group 2" v-model="data.group2"/>
            -->
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/3 w-full">
            <vs-input class="w-full" label-placeholder="No.Urut" type="number" v-model="data.no_urut"/>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <vs-input class="w-full" label-placeholder="Kode - Generate Otomatis" v-model="data.kode" disabled/>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <vs-input class="w-full" label-placeholder="Nama" v-model="data.nama"/>
          </div>
        </div>
        <div class="vx-row float-right mt-6">
          <div class="vx-col w-full">
            <vs-button class="mr-3" type="border" @click="emitModalIsActive(false)">Batal</vs-button>
            <vs-button :disabled="isLoading" @click="save">
              <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
              <span v-if="!isLoading">Simpan</span>
            </vs-button>
          </div>
        </div>
      </div>

      <!--modals-->
      <vs-popup class="sm:popup-w-50 popup-content-no-padding"
                title="Pilih Data COA"
                :active="isModalCoaActive"
                v-on:update:active="isModalCoaActive = $event">
        <Coa :externalFilter="filterCoa" :selectable="true" @selected="onSelectedModalCoa"/>
      </vs-popup>
    </vs-popup>
  </div>
</template>

<script>
import ItemRabRepository from '@/repositories/master/item-rab-repository'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import Coa from '@/views/pages/master/coa/Coa'
import ItemGroup1Repository from '@/repositories/master/item-group1-repository'
import ItemGroup2Repository from '@/repositories/master/item-group2-repository'
import vSelect from 'vue-select'
import _ from 'lodash'

export default {
  name: 'ItemRabEdit',
  components: { Coa, ValidationErrors, 'v-select': vSelect },
  props: ['isActive', 'item'],
  mounted () {
    this.getAllGroup1()
  },
  watch: {
    isActive (value) {
      if (value === true) {
        this.data = Object.assign({}, this.item)
        this.getItemGroup2ById()
      }
    },
    'data.coa' (value) {
      this.data.id_coa = value.id
    }
  },
  data () {
    return {
      isModalCoaActive: false,
      isLoading: false,
      errors: null,
      data: {
        coa: {},
        id_item_group1: null,
        id_item_group2: null,
        id_item_group3: null,
        id_item_group4: null
      },
      group1s: [],
      group2s: [],
      group3s: [],
      group4s: []
    }
  },
  computed: {
    filterCoa () {
      return {
        tipe: 'DETAIL'
      }
    }
  },
  methods: {
    getAllGroup1 () {
      ItemGroup1Repository.getAll()
        .then(response => {
          this.group1s = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    onChangeGroup1 () {
      this.group2s = []
      this.group3s = []
      this.group4s = []
      this.data.id_item_group2 = null
      this.data.id_item_group3 = null
      this.data.id_item_group4 = null
      this.getItemGroup2ById()
    },

    getItemGroup2ById () {
      const idItemGroup1 = this.data.id_item_group1
      ItemGroup2Repository.getItemGroup2ById(idItemGroup1)
        .then(response => {
          this.group2s = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    onSelectedModalCoa (item) {
      this.data.coa = item
      this.isModalCoaActive = false
    },

    save () {
      this.errors = null
      this.isLoading = true

      ItemRabRepository.update(this.data)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    onSuccess () {
      this.notifySuccess('Data berhasil disimpan.')
      this.resetData()
      this.emitIsSuccess(true)
      this.emitModalIsActive(false)
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, ['group1s', 'group2s'])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
